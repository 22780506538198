<style lang="scss" scoped>

  h1, h2, h3 {
    width: 105%;
    max-width: 720px;
    text-wrap: balance;
  }

  .photo-container {
    flex-direction: column;
    @media only screen and (min-width: 960px) {
      gap: 3.75rem;
      flex-direction: row;
    }

    .photo {
      aspect-ratio: 16 / 9;
      object-fit: cover;
      @media only screen and (min-width: 960px) {
        width: 40%;
      }
    }

    .piggy {
      background: rgba(106, 149, 63, 0.15);
      @media only screen and (max-width: 960px) {
        font-size: 0.625rem;
      }
    }

    .swoop {
      color: $brandPurple;
      white-space: nowrap;
      background-image: url('@/assets/images/swoop.svg');
      background-position: bottom -2px left 0;
    }

    h4 {
      max-width: 30rem;
      @media only screen and (max-width: 960px) {
        font-size: 0.875rem;
      }
    }
  }
</style>

<template>
  <div class="d-flex flex-column h-100">
    <v-container class="h-100 d-flex flex-column">
      <div class="d-flex align-center flex-column mb-8 mb-md-16 flex-grow-1">
        <div class="d-flex w-100 photo-container">
          <img class="photo rounded-lg" src="~/assets/images/photos/blue-house-white-trim.jpg" alt="Blue house with white trim">
          <div class="flex-1 d-flex flex-column align-center">
            <div class="mb-6 rounded-xl mt-10 px-3 py-2 text-center piggy">
              <v-icon icon="mdi-piggy-bank-outline" />&nbsp;&nbsp;{{ page.heading1 }}
            </div>
            <h1 class="mb-6 text-center">
              Apply for your mortgage, <span class="swoop">fast</span>
            </h1>
            <h4 class="mb-8 mb-md-16 text-center text-pretty">
              {{ page.body1 }}
            </h4>
            <Button class="mb-2 my-md-6" :disabled="submitting" @click="onSubmit">
              Get Started
            </Button>
            <small class="mb-2 text-center">
              By proceeding, you agree to our <a @click.prevent="openTerms">Terms of Service</a> and
              <a @click.prevent="openPrivacy">Privacy Policy</a>.
            </small>
          </div>
        </div>
      </div>
      <TermsOfService v-model="showTerms" />
      <PrivacyPolicy v-model="showPrivacy" />
    </v-container>
  </div>
</template>

<script setup>
import { useNurlaStore } from '@/store';
import { getUi, start, setToken, getToken, startState } from '@/services';
import { parseJwt } from '@/utils/jwt';

definePageMeta({
  middleware: ['affiliate', 'limiter'],
});

useHead({
  title: 'Start',
});

const nurlaStore = useNurlaStore();

const showTerms = ref(false);
const showPrivacy = ref(false);

const submitting = ref(false);

const name = useRoute().name;
const { data: page } = await getUi({ page: name });

onMounted(async () => {
  let token = getToken();
  if (!token) {
    token = await start();
  }
  await setToken(token);
  await startState();

  const { payload: { id, phone } } = parseJwt(token);
  nurlaStore.setUser({ id, phone });
});

function openTerms () {
  showTerms.value = true;
}

function openPrivacy () {
  showPrivacy.value = true;
}

async function onSubmit () {
  submitting.value = true;
  await navigateTo({ name: 'first-time' });
}
</script>
